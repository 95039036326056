import { DEBUG_TRANSLATION } from '@cookies/Constants/Cookie.constants';
import { isProd } from '@core/Utils/App/IsProd';

export const isDebugTranslationOnClient = (searchParams: URLSearchParams) => {
  const debugTranslation = searchParams.get(DEBUG_TRANSLATION);
  if (debugTranslation) {
    return (!isProd && debugTranslation) === 'on';
  }
  return false;
};
